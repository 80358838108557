import React from "react";

class Status {
  static error(message) {
    return <div className="error">{message}</div>;
  }

  static success(message) {
    return <div className="success">{message}</div>;
  }
}

export default Status;
