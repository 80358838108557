import User from "../model/User";
import Session from "../model/Session";
import Run from "../model/Run";

const backendRoot =
  process.env.NODE_ENV === "production" ? "dynamic/" : "http://localhost:8000/";

class Backend {
  static instance = new Backend();

  _previousSession = null;
  _sessionListeners = [];

  listenToSession(listener, callback) {
    if (this._previousSession) {
      this._sessionListeners.push(listener);
      if (callback) {
        callback(this._previousSession);
      }
    } else {
      this.getSession(session => {
        this._sessionListeners.push(listener);
        if (callback) {
          callback(session);
        }
      });
    }
  }

  _checkSessionInResponse(responseBody) {
    const session = this._getSessionFromResponse(responseBody);
    this._checkSessionChange(session);
  }

  _getSessionFromResponse(responseBody) {
    return responseBody.session
      ? Session.fromRaw(responseBody.session)
      : new Session();
  }

  _checkSessionChange(session) {
    if (
      this._sessionListeners.length &&
      !Session.equals(this._previousSession, session)
    ) {
      this._previousSession = session;
      this._sessionListeners.forEach(listener => listener(session));
    }
  }

  getSession(callback) {
    performAsync("session", null, responseBody => {
      const session = this._getSessionFromResponse(responseBody);
      if (callback) {
        callback(session);
      }
      this._checkSessionChange(session);
    });
  }

  logIn(username, password, rememberMe, callback) {
    const requestBody = {
      username: username,
      password: password,
      rememberme: rememberMe
    };
    performAsync("login", requestBody, responseBody => {
      const session = this._getSessionFromResponse(responseBody);
      if (callback) {
        callback(session);
      }
      this._checkSessionChange(session);
    });
  }

  logOut(callback) {
    performAsync("logout", null, responseBody => {
      const session = this._getSessionFromResponse(responseBody);
      if (callback) {
        callback(session);
      }
      this._checkSessionChange(session);
    });
  }

  addRun(distance, date, successCallback, failureCallback) {
    const requestBody = {
      distance: distance,
      date: date
    };
    performAsync(
      "register_run",
      requestBody,
      responseBody => {
        if (successCallback) {
          successCallback(Run.fromRaw(responseBody.run));
        }
        this._checkSessionInResponse(responseBody);
      },
      (responseBody, httpResponseCode) => {
        if (failureCallback) {
          failureCallback(httpResponseCode);
        }
        this._checkSessionInResponse(responseBody);
      }
    );
  }

  removeRun(id, successCallback, failureCallback) {
    const requestBody = {
      id: id
    };
    performAsync(
      "remove_run",
      requestBody,
      responseBody => {
        if (successCallback) {
          successCallback();
        }
        this._checkSessionInResponse(responseBody);
      },
      (responseBody, httpResponseCode) => {
        if (failureCallback) {
          failureCallback(httpResponseCode);
        }
        this._checkSessionInResponse(responseBody);
      }
    );
  }

  getRuns(successCallback, failureCallback) {
    performAsync(
      "get_runs",
      null,
      responseBody => {
        if (successCallback) {
          successCallback(Run.fromRaw(responseBody.runs));
        }
        this._checkSessionInResponse(responseBody);
      },
      (responseBody, httpResponseCode) => {
        if (failureCallback) {
          failureCallback(httpResponseCode);
        }
        this._checkSessionInResponse(responseBody);
      }
    );
  }

  getUsers(successCallback, failureCallback) {
    performAsync(
      "get_users",
      null,
      responseBody => {
        if (successCallback) {
          successCallback(User.fromRaw(responseBody.users));
        }
        this._checkSessionInResponse(responseBody);
      },
      (responseBody, httpResponseCode) => {
        if (failureCallback) {
          failureCallback(httpResponseCode);
        }
        this._checkSessionInResponse(responseBody);
      }
    );
  }

  changeSettings(settings, successCallback, failureCallback) {
    const requestBody = {
      settings: settings
    };
    performAsync(
      "set_settings",
      requestBody,
      responseBody => {
        const session = this._getSessionFromResponse(responseBody);
        if (successCallback) {
          successCallback(session.settings);
        }
        this._checkSessionChange(session);
      },
      (responseBody, httpResponseCode) => {
        if (failureCallback) {
          failureCallback(httpResponseCode);
        }
        this._checkSessionInResponse(responseBody);
      }
    );
  }

  changePassword(
    userId,
    oldPassword,
    newPassword,
    successCallback,
    failureCallback
  ) {
    const requestBody = {
      userId: userId,
      oldPassword: oldPassword,
      newPassword: newPassword
    };
    performAsync(
      "set_password",
      requestBody,
      responseBody => {
        if (successCallback) {
          successCallback();
        }
        this._checkSessionInResponse(responseBody);
      },
      (responseBody, httpResponseCode) => {
        if (failureCallback) {
          failureCallback(httpResponseCode);
        }
        this._checkSessionInResponse(responseBody);
      }
    );
  }

  addUser(
    username,
    password,
    firstname,
    lastname,
    successCallback,
    failureCallback
  ) {
    const requestBody = {
      username: username,
      password: password,
      firstname: firstname,
      lastname: lastname
    };
    performAsync(
      "add_user",
      requestBody,
      responseBody => {
        if (successCallback) {
          successCallback(User.fromRaw(responseBody.user));
        }
        this._checkSessionInResponse(responseBody);
      },
      (responseBody, httpResponseCode) => {
        if (failureCallback) {
          failureCallback(httpResponseCode);
        }
        this._checkSessionInResponse(responseBody);
      }
    );
  }
}

function performAsync(action, requestBody, successCallback, failureCallback) {
  const url = backendRoot + "api.php?action=" + action;
  return postAsync(url, requestBody, successCallback, failureCallback);
}

function postAsync(url, requestBody, successCallback, failureCallback) {
  const xhr = new XMLHttpRequest();
  xhr.open("POST", url, true);
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      const httpResponseCode = xhr.status;
      const responseBody = xhr.responseText ? JSON.parse(xhr.responseText) : {};
      if (httpResponseCode >= 200 && httpResponseCode < 300) {
        if (successCallback) {
          successCallback(responseBody, httpResponseCode);
        }
      } else {
        if (failureCallback) {
          failureCallback(responseBody, httpResponseCode);
        }
      }
    }
  };
  xhr.setRequestHeader("Content-Type", "text/plain; charset=UTF-8");
  xhr.withCredentials = true;
  xhr.send(requestBody ? JSON.stringify(requestBody) : {});
  return xhr;
}

export default Backend.instance;
