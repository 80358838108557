import React, { Component } from "react";
import moment from "moment";
import { Line } from "react-chartjs-2";
import ArrayUtil from "../util/ArrayUtil";

class LastWeeksChart extends Component {
  render() {
    const { numWeeks, runs } = this.props;
    let monday = moment().startOf("isoWeek");
    const weekLabels = ["nuvarande"];
    const mondays = [monday.format("YYYY-MM-DD")];
    for (let i = 0; i < numWeeks; i++) {
      monday = monday.subtract(1, "w");
      weekLabels.unshift(monday.format("W"));
      mondays.unshift(monday.format("YYYY-MM-DD"));
    }
    const kilometers = runs.reduce((result, run) => {
      if (run.date >= mondays[0]) {
        for (let i = mondays.length - 1; i >= 0; i--) {
          if (run.date >= mondays[i]) {
            const km = run.distance / 1000;
            result[i] += km;
            break;
          }
        }
      }
      return result;
    }, ArrayUtil.createFilled(mondays.length, 0));
    return (
      <div style={{ minHeight: "300px" }}>
        <Line
          data={this.createData(
            weekLabels,
            kilometers,
            ArrayUtil.calculateAverage(kilometers.slice(0, -1))
          )}
          options={this.createOptions()}
        />
      </div>
    );
  }

  createData(weekLabels, kilometers, average) {
    const { numWeeks, color, weeklyGoal } = this.props;
    const mainCssColor = this.toCssColor(color);
    const mainCssColorSemiTransparent = this.toSemiTransparentCssColor(color);
    const transparentCssColor = "rgba(0, 0, 0, 0)";
    const datasets = [
      {
        label: "Distans",
        backgroundColor: transparentCssColor,
        borderColor: mainCssColor,
        data: kilometers,
        lineTension: 0
      }
    ];
    if (weeklyGoal && weeklyGoal > 0) {
      datasets.push({
        label: "Veckomål",
        backgroundColor: transparentCssColor,
        borderColor: "rgb(50, 230, 80)",
        borderWidth: 1,
        data: ArrayUtil.createFilled(numWeeks + 1, weeklyGoal / 1000),
        lineTension: 0,
        pointRadius: 0
      });
    }
    datasets.push({
      label: "Snitt",
      backgroundColor: mainCssColorSemiTransparent,
      borderColor: mainCssColor,
      borderWidth: 1,
      data: ArrayUtil.createFilled(numWeeks + 1, average),
      lineTension: 0,
      pointRadius: 0
    });
    return {
      labels: weekLabels,
      datasets
    };
  }

  createOptions() {
    const { title } = this.props;
    return {
      title: {
        display: true,
        text: title
      },
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "vecka"
            }
          }
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "km"
            },
            ticks: {
              min: 0,
              precision: 0
            }
          }
        ]
      },
      tooltips: {
        callbacks: {
          title: tooltipItems =>
            tooltipItems[0].datasetIndex > 0
              ? ""
              : tooltipItems[0].label === "nuvarande"
              ? "nuvarande vecka"
              : "v. " + tooltipItems[0].label,
          label: (tooltipItem, data) =>
            data.datasets[tooltipItem.datasetIndex].label +
            ": " +
            Math.round(tooltipItem.value * 10) / 10 +
            " km"
        }
      }
    };
  }

  toCssColor(color) {
    return "rgb(" + color[0] + ", " + color[1] + ", " + color[2] + ")";
  }

  toSemiTransparentCssColor(color) {
    return "rgba(" + color[0] + ", " + color[1] + ", " + color[2] + ", 0.1)";
  }
}

export default LastWeeksChart;
