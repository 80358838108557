import React, { Component } from "react";
import Backend from "../util/Backend";
import Status from "./static/Status";

class ChangePassword extends Component {
  state = {
    userId: -1,
    users: [],
    status: null,
    oldPassword: "",
    newPassword1: "",
    newPassword2: ""
  };

  componentDidMount() {
    const { user } = this.props.session;
    if (user.admin) {
      this.setState({ userId: user.id });
      Backend.getUsers(
        users => this.onLoadUsersSucceeded(users),
        errorCode => this.onLoadUsersFailed(errorCode)
      );
    }
  }

  onLoadUsersSucceeded(users) {
    this.setState({ status: null, users });
  }

  onLoadUsersFailed(errorCode) {
    switch (errorCode) {
      default:
        this.setState({
          status: Status.error("Ett oväntat fel inträffade"),
          users: []
        });
    }
  }

  handleInputChange(event) {
    const { name, type, value, checked } = event.target;
    if (type === "checkbox") {
      this.setState({
        [name]: checked
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  }

  handleSubmit(event) {
    const { user } = this.props.session;
    const { userId, oldPassword, newPassword1, newPassword2 } = this.state;
    if (!user.admin && oldPassword.length === 0) {
      this.setState({ status: Status.error("Du måste ange gammalt lösenord") });
    } else if (newPassword1.length < 4) {
      this.setState({
        status: Status.error(
          "Det nya lösenordet måste vara minst 4 tecken långt"
        )
      });
    } else if (newPassword1.length > 100) {
      this.setState({
        status: Status.error("Det nya lösenordet får vara max 100 tecken långt")
      });
    } else if (oldPassword === newPassword1) {
      this.setState({
        status: Status.error("Det nya lösenordet är samma som det gamla")
      });
    } else if (newPassword1 !== newPassword2) {
      this.setState({ status: Status.error("De nya lösenorden matchar inte") });
    } else {
      Backend.changePassword(
        userId,
        oldPassword,
        newPassword1,
        () => this.onPasswordChangeSucceeded(),
        errorCode => this.onPasswordChangeFailed(errorCode)
      );
    }
    event.preventDefault();
  }

  onPasswordChangeSucceeded() {
    this.setState({
      status: Status.success("Lösenordet har ändrats"),
      oldPassword: "",
      newPassword1: "",
      newPassword2: ""
    });
  }

  onPasswordChangeFailed(errorCode) {
    switch (errorCode) {
      case 401:
        this.setState({
          status: Status.error("Det gamla lösenordet stämmer inte")
        });
        break;
      default:
        this.setState({ status: Status.error("Ett oväntat fel inträffade") });
    }
  }

  render() {
    return (
      <div>
        <h1>Inställningar</h1>
        {this.renderUserSelection()}
        <h2>Ändra lösenord</h2>
        {this.state.status}
        <form onSubmit={event => this.handleSubmit(event)} method="post">
          <div className="form-group">
            <label htmlFor="changePasswordOldPassword">Gammalt lösenord</label>
            <input
              id="changePasswordOldPassword"
              className="form-control"
              name="oldPassword"
              type="password"
              value={this.state.oldPassword}
              placeholder="Ange gammalt lösenord"
              onChange={event => this.handleInputChange(event)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="changePasswordNewPassword1">Nytt lösenord</label>
            <input
              id="changePasswordNewPassword1"
              className="form-control"
              name="newPassword1"
              type="password"
              value={this.state.newPassword1}
              placeholder="Ange nytt lösenord"
              onChange={event => this.handleInputChange(event)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="changePasswordNewPassword2">
              Upprepa nytt lösenord
            </label>
            <input
              id="changePasswordNewPassword2"
              className="form-control"
              name="newPassword2"
              type="password"
              value={this.state.newPassword2}
              placeholder="Ange nytt lösenord igen"
              onChange={event => this.handleInputChange(event)}
            />
          </div>
          <button className="btn btn-primary" type="submit">
            Spara
          </button>
        </form>
        <p className="pt-3">
          <button className="btn btn-link" onClick={this.props.back}>
            Tillbaka
          </button>
        </p>
      </div>
    );
  }

  renderUserSelection() {
    const { user } = this.props.session;
    if (user.admin && this.state.users) {
      return (
        <div>
          <p>
            <select
              name="userId"
              value={this.state.userId}
              onChange={event => this.handleInputChange(event)}
            >
              {this.state.users
                .sort((lhs, rhs) => {
                  if (lhs.firstname < rhs.firstname) return -1;
                  if (lhs.firstname > rhs.firstname) return 1;
                  if (lhs.lastname < rhs.lastname) return -1;
                  if (lhs.lastname > rhs.lastname) return 1;
                  if (lhs.username < rhs.username) return -1;
                  if (lhs.username > rhs.username) return 1;
                  return 0;
                })
                .map(user => (
                  <option value={user.id} key={user.id}>
                    {user.firstname} {user.lastname} ({user.username})
                  </option>
                ))}
            </select>
          </p>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default ChangePassword;
