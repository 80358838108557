import React, { Component } from "react";
import "./App.css";
import menuIcon from "../media/menu.svg";
import Session from "../model/Session";
import Backend from "../util/Backend";
import MetaData from "../util/MetaData";
import AddRun from "./AddRun";
import AddUser from "./AddUser";
import ChangeSettings from "./ChangeSettings";
import Login from "./Login";
import Modal from "./Modal";
import Statistics from "./Statistics";

const enumValue = val => Object.freeze({ toString: () => val });

const Pages = Object.freeze({
  LOGIN: enumValue("Pages.LOGIN"),
  ADD_RUN: enumValue("Pages.ADD_RUN"),
  STATISTICS: enumValue("Pages.STATISTICS"),
  SETTINGS: enumValue("Pages.SETTINGS"),
  ADD_USER: enumValue("Pages.ADD_USER")
});

class App extends Component {
  state = {
    loading: true,
    session: new Session(null, null),
    page: Pages.LOGIN
  };

  setSession(session) {
    if (Session.equals(session, this.state.session)) {
      return;
    }
    const { page } = this.state;
    const newState = { session };
    if (session.user) {
      if (
        page === Pages.LOGIN ||
        (!session.user.admin && page === Pages.ADD_USER)
      ) {
        newState.page = Pages.ADD_RUN;
      }
    } else if (page !== Pages.LOGIN) {
      newState.page = Pages.LOGIN;
    }
    this.setState(newState);
  }

  setPage(page) {
    if (page === this.state.page) {
      return;
    }
    const { session } = this.state;
    if (session.user) {
      if (!session.user.admin && page === Pages.ADD_USER) {
        return;
      }
    } else if (page !== Pages.LOGIN) {
      return;
    }
    this.setState({ page });
  }

  componentDidMount() {
    Backend.listenToSession(
      session => {
        this.setSession(session);
      },
      session => {
        this.setSession(session);
        this.setState({ loading: false });
      }
    );
  }

  handleLogOut() {
    Backend.logOut();
  }

  render() {
    const { loading, session, page } = this.state;
    const { user } = session;
    let view;
    switch (page) {
      case Pages.ADD_RUN:
        view = <AddRun />;
        break;
      case Pages.STATISTICS:
        view = <Statistics session={session} />;
        break;
      case Pages.SETTINGS:
        view = <ChangeSettings session={session} />;
        break;
      case Pages.ADD_USER:
        view = <AddUser />;
        break;
      case Pages.LOGIN:
      default:
        view = null;
    }
    const createNavItem = (label, target) => {
      const className = "nav-item" + (target === page ? " active" : "");
      const action =
        typeof target === "function" ? target : () => this.setPage(target);
      return (
        <li className={className} style={{ cursor: "pointer" }}>
          <span className="nav-link" onClick={action}>
            {label}
          </span>
        </li>
      );
    };
    return (
      <React.Fragment>
        <nav
          className="navbar navbar-dark navbar-expand-md align-items-md-start flex-column "
          style={{ background: "#362336" }}
          role="navigation"
        >
          <div className="navbar-brand ">Indalsledenloppet 2087</div>
          {view && (
            <React.Fragment>
              <small className="navbar-text">
                Inloggad som {user.firstname} {user.lastname}
              </small>
              <button
                className="navbar-toggler m-2"
                type="button"
                data-toggle="collapse"
                data-target="#navbarMenu"
              >
                <img src={menuIcon} alt="Meny" />
              </button>
              <div id="navbarMenu" className="collapse navbar-collapse">
                <ul className="navbar-nav mr-auto">
                  {createNavItem("Registrera runda", Pages.ADD_RUN)}
                  {createNavItem("Statistik", Pages.STATISTICS)}
                  {createNavItem("Inställningar", Pages.SETTINGS)}
                  {user.admin &&
                    createNavItem("Lägg till användare", Pages.ADD_USER)}
                  {createNavItem("Logga ut", () => this.handleLogOut())}
                </ul>
              </div>
            </React.Fragment>
          )}
        </nav>
        <div className="App mt-4 mb-4 col-md-8 col-lg-6 text-md-left">
          {(loading && this.renderSpinner()) || view || <Login />}
        </div>
        <footer className="col-12 small text-center text-md-left text-black-20 pt-4 pb-2">
          Version {MetaData.getVersion()} &copy; {MetaData.getBuildYear()}
          <br />
          Powered by LoPoBo
        </footer>
        <Modal />
      </React.Fragment>
    );
  }

  renderSpinner() {
    return (
      <div className="spinner-border text-secondary" role="status">
        <span className="sr-only">Laddar…</span>
      </div>
    );
  }
}

export default App;
