import React, { Component } from "react";
import Backend from "../util/Backend";
import Status from "./static/Status";

class AddRun extends Component {
  state = {
    status: null,
    distance: "",
    date: this.toIsoDateString(new Date())
  };

  handleInputChange(event) {
    const { name, type, value, checked } = event.target;
    if (type === "checkbox") {
      this.setState({
        [name]: checked
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  }

  handleSubmit(event) {
    const { distance, date } = this.state;
    const distanceMeters = this.toMeters(distance);
    if (!distance) {
      this.setState({ status: Status.error("Du måste ange en distans") });
    } else if (isNaN(distanceMeters)) {
      this.setState({
        status: Status.error(
          "Distansen måste vara ett nummer, med upp till 3 decimaler"
        )
      });
    } else if (distanceMeters < 1) {
      this.setState({
        status: Status.error("Distansen måste vara minst 0,001 (en meter)")
      });
    } else if (!/\d{4}-\d{2}-\d{2}/.test(date)) {
      this.setState({
        status: Status.error("Datumet måste vara angivet i formatet ÅÅÅÅ-MM-DD")
      });
    } else if (
      date.substr(0, 2) !== "20" ||
      date !== this.toIsoDateString(new Date(date))
    ) {
      this.setState({
        status: Status.error("Datumet verkar inte vara korrekt")
      });
    } else if (this.isInFuture(date)) {
      this.setState({ status: Status.error("Datumet är i framtiden") });
    } else {
      Backend.addRun(
        this.toMeters(distance),
        date,
        run => this.onAddRunSucceeded(run),
        errorCode => this.onAddRunFailed(errorCode)
      );
    }
    event.preventDefault();
  }

  toIsoDateString(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return (
      year +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day)
    );
  }

  isInFuture(date) {
    return (
      this.toIsoDateString(new Date(date)) > this.toIsoDateString(new Date())
    );
  }

  onAddRunSucceeded(run) {
    this.setState({
      status: Status.success(
        "Löprunda tillagd (" + run.distance / 1000 + " km)"
      ),
      distance: ""
    });
  }

  onAddRunFailed(errorCode) {
    switch (errorCode) {
      default:
        this.setState({ status: Status.error("Ett oväntat fel inträffade") });
    }
  }

  render() {
    return (
      <div>
        <h1>Registrera löprunda</h1>
        {this.state.status}
        <form onSubmit={event => this.handleSubmit(event)} method="post">
          <div className="form-group">
            <label htmlFor="addRunDistance">Distans i km</label>
            <input
              id="addRunDistance"
              className="form-control"
              name="distance"
              type="number"
              value={this.state.distance}
              min="0"
              step="0.001"
              placeholder="Ange distans i km med upp till 3 decimaler"
              onChange={event => this.handleInputChange(event)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="addRunDate">Datum</label>
            <input
              id="addRunDate"
              className="form-control"
              name="date"
              type="text"
              value={this.state.date}
              placeholder="Ange datum (ÅÅÅÅ-MM-DD)"
              onChange={event => this.handleInputChange(event)}
            />
          </div>
          <button className="btn btn-primary" type="submit">
            Spara
          </button>
        </form>
      </div>
    );
  }

  toMeters(kilometers) {
    return Math.round(kilometers.replace(",", ".") * 1000);
  }
}

export default AddRun;
