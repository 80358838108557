import React, { Component } from "react";
import Backend from "../util/Backend";
import Status from "./static/Status";
import ChangePassword from "./ChangePassword";
import Settings from "../model/Settings";

class ChangeSettings extends Component {
  state = {
    status: null,
    weeklyGoal: this.weeklyGoalToKm(this.props.session.settings.weeklyGoal),
    changingPassword: false
  };

  reloadState() {
    const { settings } = this.props.session;
    this.setState({
      status: null,
      weeklyGoal: this.weeklyGoalToKm(settings.weeklyGoal),
      changingPassword: false
    });
  }

  handleInputChange(event) {
    const { name, type, value, checked } = event.target;
    if (type === "checkbox") {
      this.setState({
        [name]: checked
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  }

  handleSubmit(event) {
    const { settings: oldSettings } = this.props.session;
    const weeklyGoal = this.textToWeeklyGoal(this.state.weeklyGoal);
    const newSettings = new Settings(weeklyGoal);
    if (newSettings.equals(oldSettings)) {
      this.onSettingsChangeSucceeded(oldSettings);
    } else if (weeklyGoal >= 1000000) {
      this.setState({ status: Status.error("Veckomålet är för högt") });
    } else {
      Backend.changeSettings(
        newSettings,
        savedSettings => this.onSettingsChangeSucceeded(savedSettings),
        errorCode => this.onSettingsChangeFailed(errorCode)
      );
    }
    event.preventDefault();
  }

  onSettingsChangeSucceeded(savedSettings) {
    this.setState({
      status: Status.success("Inställningarna har sparats"),
      weeklyGoal: this.weeklyGoalToKm(savedSettings.weeklyGoal)
    });
  }

  onSettingsChangeFailed(errorCode) {
    switch (errorCode) {
      default:
        this.setState({ status: Status.error("Ett oväntat fel inträffade") });
    }
  }

  render() {
    if (this.state.changingPassword) {
      return (
        <ChangePassword
          session={this.props.session}
          back={() => this.reloadState()}
        />
      );
    }
    return (
      <div>
        <h1>Inställningar</h1>
        {this.state.status}
        <form onSubmit={event => this.handleSubmit(event)} method="post">
          <div className="form-group">
            <label htmlFor="changeSettingsWeeklyGoal">Veckomål (km)</label>
            <input
              id="changeSettingsWeeklyGoal"
              className="form-control"
              name="weeklyGoal"
              type="number"
              value={this.state.weeklyGoal}
              min="0"
              step="0.1"
              lang="sv"
              placeholder="Ange veckomål"
              onChange={event => this.handleInputChange(event)}
            />
          </div>
          <button className="btn btn-primary" type="submit">
            Spara
          </button>
        </form>
        <p className="pt-3">
          <button
            className="btn btn-link"
            onClick={() => this.setState({ changingPassword: true })}
          >
            Ändra lösenord
          </button>
        </p>
      </div>
    );
  }

  weeklyGoalToKm(weeklyGoal) {
    if (weeklyGoal) {
      weeklyGoal = Math.round(weeklyGoal / 100);
      if (weeklyGoal > 0) {
        return weeklyGoal / 10;
      }
    }
    return null;
  }

  textToWeeklyGoal(text) {
    if (text) {
      const weeklyGoal = Math.round(text.replace(",", ".") * 1000);
      if (weeklyGoal > 0) {
        return weeklyGoal;
      }
    }
    return 0;
  }
}

export default ChangeSettings;
