import React, { Component } from "react";
import removeIcon from "../media/remove.svg";
import Backend from "../util/Backend";
import Status from "./static/Status";
import LastWeeksChart from "./LastWeeksChart";
import Modal from "./Modal";

const RECENT_RUN_DATE = new Date("2019-06-15");

class Statistics extends Component {
  state = {
    status: null,
    runs: []
  };

  componentDidMount() {
    this.reload();
  }

  reload() {
    Backend.getRuns(
      runs => this.onLoadRunsSucceeded(runs),
      errorCode => this.onLoadRunsFailed(errorCode)
    );
  }

  onLoadRunsSucceeded(runs) {
    this.setState({ status: null, runs });
  }

  onLoadRunsFailed(errorCode) {
    switch (errorCode) {
      default:
        this.setState({
          status: Status.error("Ett oväntat fel inträffade"),
          runs: []
        });
    }
  }

  render() {
    const { runs } = this.state;
    const {
      user: { id: userId },
      settings: { weeklyGoal }
    } = this.props.session;
    const userRuns = runs
      .filter(run => run.userId === userId)
      .sort((lhs, rhs) => {
        if (lhs.date < rhs.date) return 1;
        if (lhs.date > rhs.date) return -1;
        return 0;
      });
    const sumDistances = runs => {
      return runs.reduce(
        (sum, run) => {
          sum.total += run.distance;
          if (RECENT_RUN_DATE < new Date(run.date)) {
            sum.recent += run.distance;
          }
          return sum;
        },
        { total: 0, recent: 0 }
      );
    };
    const userDistances = sumDistances(userRuns);
    const distances = sumDistances(runs);
    return (
      <div>
        <h1>Statistik</h1>
        {this.state.status}
        <h2>Senaste veckorna</h2>
        <LastWeeksChart
          title="Dina senaste 5 veckor"
          numWeeks={5}
          runs={userRuns}
          color={[132, 99, 255]}
          weeklyGoal={weeklyGoal}
        />
        <LastWeeksChart
          title="Allas senaste 5 veckor"
          numWeeks={5}
          runs={runs}
          color={[255, 99, 132]}
        />
        <h2>Summa</h2>
        <p>
          Du har sprungit totalt {this.toKilometers(userDistances.total)} km (
          {this.toKilometers(userDistances.recent)} km sedan senaste loppet).
        </p>
        <p>
          Alla har sprungit totalt {this.toKilometers(distances.total)} km (
          {this.toKilometers(distances.recent)} km sedan senaste loppet).
        </p>
        <h2>Vår resa runt jorden</h2>
        {this.renderEarthProgress(distances.total)}
        <h2>Dina löprundor</h2>
        {userRuns.length < 1 && "Inga än…"}
        {userRuns.length > 0 && (
          <table className="table table-sm">
            <thead className="thead-light">
              <tr>
                <th scope="col">Avstånd</th>
                <th scope="col">Datum</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {userRuns.map((run, index) => {
                return (
                  <tr key={index}>
                    <td>{this.toKilometers(run.distance)} km</td>
                    <td>{run.date}</td>
                    <td className="text-right">
                      <button
                        className="btn btn-link"
                        onClick={() => this.removeRun(run)}
                      >
                        <img src={removeIcon} alt="Ta bort" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    );
  }

  renderEarthProgress(totalDistance) {
    const earthCircumference = 40075017;
    const percentage =
      Math.floor((100 * totalDistance) / earthCircumference) + "%";
    return (
      <div>
        <p>
          Vi har {this.toKilometers(earthCircumference - totalDistance)} km kvar
          innan vi har sprungit ett varv runt jorden!
        </p>
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: percentage
            }}
            aria-valuenow={Math.floor(totalDistance / 1000)}
            aria-valuemin="0"
            aria-valuemax={Math.floor(earthCircumference / 1000)}
          >
            {percentage}
          </div>
        </div>
      </div>
    );
  }

  toKilometers(meters) {
    return (meters / 1000).toLocaleString("sv");
  }

  removeRun(run) {
    Modal.show(
      "Ta bort löprunda?",
      `Är du säker på att du vill ta bort din löprunda på ${this.toKilometers(
        run.distance
      )} km från ${run.date}? Detta går inte att ångra.`,
      "Ta bort",
      () => {
        Backend.removeRun(
          run.id,
          () => this.reload(),
          () => this.reload()
        );
      },
      "Avbryt"
    );
  }
}

export default Statistics;
