import React, { Component } from "react";
import Backend from "../util/Backend";

class Login extends Component {
  state = {
    username: "",
    password: "",
    rememberMe: true
  };

  handleInputChange(event) {
    const { name, type, value, checked } = event.target;
    if (type === "checkbox") {
      this.setState({
        [name]: checked
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  }

  handleSubmit(event) {
    const { username, password, rememberMe } = this.state;
    Backend.logIn(username, password, rememberMe);
    event.preventDefault();
  }

  render() {
    return (
      <div>
        <h1>Logga in</h1>
        <form onSubmit={event => this.handleSubmit(event)} method="post">
          <div className="form-group">
            <label htmlFor="loginUsername">Användarnamn</label>
            <input
              id="loginUsername"
              className="form-control"
              name="username"
              type="text"
              value={this.state.username}
              placeholder="Ange användarnamn"
              onChange={event => this.handleInputChange(event)}
              autoFocus
            />
          </div>
          <div className="form-group">
            <label htmlFor="loginPassword">Lösenord</label>
            <input
              id="loginPassword"
              className="form-control"
              name="password"
              type="password"
              value={this.state.password}
              placeholder="Ange lösenord"
              onChange={event => this.handleInputChange(event)}
            />
          </div>
          <div className="form-check">
            <input
              id="loginRememberMe"
              className="form-check-input"
              name="rememberMe"
              type="checkbox"
              checked={this.state.rememberMe}
              onChange={event => this.handleInputChange(event)}
            />
            <label className="form-check-label" htmlFor="loginRememberMe">
              Kom ihåg mig
            </label>
          </div>
          <button className="btn btn-primary" type="submit">
            Logga in
          </button>
        </form>
      </div>
    );
  }
}

export default Login;
