class Settings {
  weeklyGoal = 0;

  constructor(weeklyGoal) {
    this.weeklyGoal = weeklyGoal;
  }

  static fromRaw(raw) {
    if (raw == null) {
      return null;
    } else if (Array.isArray(raw)) {
      return raw.map(val => this.fromRaw(val));
    } else {
      return new Settings(raw.weeklyGoal);
    }
  }

  equals(that) {
    return (
      this === that ||
      (that instanceof Settings && this.weeklyGoal === that.weeklyGoal)
    );
  }

  static equals(lhs, rhs) {
    return lhs === rhs || (lhs instanceof Settings && lhs.equals(rhs));
  }
}

export default Settings;
