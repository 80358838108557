import User from "./User";
import Settings from "./Settings";

class Session {
  user = null;
  settings = null;

  constructor(user, settings) {
    this.user = user === undefined ? null : user;
    this.settings = settings === undefined ? null : settings;
  }

  static fromRaw(raw) {
    if (raw == null) {
      return null;
    } else if (Array.isArray(raw)) {
      return raw.map(val => this.fromRaw(val));
    } else {
      return new Session(
        User.fromRaw(raw.user),
        Settings.fromRaw(raw.settings)
      );
    }
  }

  equals(that) {
    return (
      this === that ||
      (that instanceof Session &&
        User.equals(this.user, that.user) &&
        Settings.equals(this.settings, that.settings))
    );
  }

  static equals(lhs, rhs) {
    return lhs === rhs || (lhs instanceof Session && lhs.equals(rhs));
  }
}

export default Session;
