class Run {
  id = -1;
  userId = -1;
  distance = 0;
  date = "";

  constructor(id, userId, distance, date) {
    this.id = id;
    this.userId = userId;
    this.distance = distance;
    this.date = date;
  }

  static fromRaw(raw) {
    if (raw == null) {
      return null;
    } else if (Array.isArray(raw)) {
      return raw.map(val => this.fromRaw(val));
    } else {
      return new Run(raw.id, raw.userId, raw.distance, raw.date);
    }
  }

  equals(that) {
    return (
      this === that ||
      (that instanceof Run &&
        this.id === that.id &&
        this.userId === that.userId &&
        this.distance === that.distance &&
        this.date === that.date)
    );
  }

  static equals(lhs, rhs) {
    return lhs === rhs || (lhs instanceof Run && lhs.equals(rhs));
  }
}

export default Run;
