import React, { Component } from "react";
import $ from "jquery";

const MODAL_ID = "generalModal";
const NOP = () => undefined;

class Modal extends Component {
  static show(
    title,
    text,
    primaryButtonText,
    primaryButtonAction = NOP,
    secondaryButtonText = null,
    secondaryButtonAction = NOP
  ) {
    $("#" + MODAL_ID + "_title").html(title);
    $("#" + MODAL_ID + "_text").html(text);
    $("#" + MODAL_ID + "_primary")
      .html(primaryButtonText)
      .off("click")
      .on("click", primaryButtonAction);
    const secondaryButton = $("#" + MODAL_ID + "_secondary");
    if (secondaryButtonText) {
      secondaryButton
        .html(secondaryButtonText)
        .off("click")
        .on("click", secondaryButtonAction)
        .show();
    } else {
      secondaryButton.hide();
    }
    $("#" + MODAL_ID).modal("show");
  }

  render() {
    return (
      <div
        id={MODAL_ID}
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        aria-labelledby={MODAL_ID + "_title"}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 id={MODAL_ID + "_title"} className="modal-title">
                …
              </h5>
            </div>
            <div id={MODAL_ID + "_text"} className="modal-body">
              …
            </div>
            <div className="modal-footer">
              <button
                id={MODAL_ID + "_secondary"}
                className="btn btn-secondary"
                type="button"
                data-dismiss="modal"
              >
                …
              </button>
              <button
                id={MODAL_ID + "_primary"}
                className="btn btn-primary"
                type="button"
                data-dismiss="modal"
              >
                …
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
