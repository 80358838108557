class User {
  id = -1;
  username = "";
  firstname = "";
  lastname = "";
  admin = false;

  constructor(id, username, firstname, lastname, admin) {
    this.id = typeof id === "number" ? Math.floor(id) : -1;
    this.username = username;
    this.firstname = firstname;
    this.lastname = lastname;
    this.admin = admin === true;
  }

  static fromRaw(raw) {
    if (raw == null) {
      return null;
    } else if (Array.isArray(raw)) {
      return raw.map(val => this.fromRaw(val));
    } else {
      return new User(
        raw.id,
        raw.username,
        raw.firstname,
        raw.lastname,
        raw.admin
      );
    }
  }

  equals(that) {
    return (
      this === that ||
      (that instanceof User &&
        this.id === that.id &&
        this.username === that.username &&
        this.firstname === that.firstname &&
        this.lastname === that.lastname &&
        this.admin === that.admin)
    );
  }

  static equals(lhs, rhs) {
    return lhs === rhs || (lhs instanceof User && lhs.equals(rhs));
  }
}

export default User;
