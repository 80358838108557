class ArrayUtil {
  static instance = new ArrayUtil();

  createFilled(length, value) {
    const result = [];
    while (result.length < length) {
      result.push(value);
    }
    return result;
  }

  calculateAverage(array) {
    if (array.length === 0) {
      return 0;
    }
    return array.reduce((sum, value) => sum + value, 0) / array.length;
  }
}

export default ArrayUtil.instance;
