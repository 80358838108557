import React, { Component } from "react";
import Backend from "../util/Backend";
import Status from "./static/Status";

class AddUser extends Component {
  state = {
    status: null,
    firstname: "",
    lastname: "",
    username: "",
    password1: "",
    password2: ""
  };

  handleInputChange(event) {
    const { name, type, value, checked } = event.target;
    if (type === "checkbox") {
      this.setState({
        [name]: checked
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  }

  handleSubmit(event) {
    const { firstname, lastname, username, password1, password2 } = this.state;
    if (password1 !== password2) {
      this.setState({ status: Status.error("Lösenorden matchar inte") });
    } else {
      Backend.addUser(
        username,
        password1,
        firstname,
        lastname,
        newUser => this.onAddUserSucceeded(newUser),
        errorCode => this.onAddUserFailed(errorCode)
      );
    }
    event.preventDefault();
  }

  onAddUserSucceeded(newUser) {
    this.setState({
      status: Status.success("Användare " + newUser.username + " tillagd"),
      firstname: "",
      lastname: "",
      username: "",
      password1: "",
      password2: ""
    });
  }

  onAddUserFailed(errorCode) {
    switch (errorCode) {
      default:
        this.setState({
          status: Status.error("Ett oväntat fel inträffade (" + errorCode + ")")
        });
    }
  }

  render() {
    return (
      <div>
        <h1>Lägg till användare</h1>
        {this.state.status}
        <form onSubmit={event => this.handleSubmit(event)} method="post">
          <div className="form-group">
            <label htmlFor="addUserFirstname">Förnamn</label>
            <input
              id="addUserFirstname"
              className="form-control"
              name="firstname"
              type="text"
              value={this.state.firstname}
              placeholder="Ange förnamn"
              onChange={event => this.handleInputChange(event)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="addUserLastname">Efternamn</label>
            <input
              id="addUserLastname"
              className="form-control"
              name="lastname"
              type="text"
              value={this.state.lastname}
              placeholder="Ange efternamn"
              onChange={event => this.handleInputChange(event)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="addUserUsername">Användarnamn</label>
            <input
              id="addUserUsername"
              className="form-control"
              name="username"
              type="text"
              value={this.state.username}
              placeholder="Ange användarnamn"
              onChange={event => this.handleInputChange(event)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="addUserPassword1">Lösenord</label>
            <input
              id="addUserPassword1"
              className="form-control"
              name="password1"
              type="password"
              value={this.state.password1}
              placeholder="Ange lösenord"
              onChange={event => this.handleInputChange(event)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="addUserPassword2">Upprepa lösenord</label>
            <input
              id="addUserPassword2"
              className="form-control"
              name="password2"
              type="password"
              value={this.state.password2}
              placeholder="Ange lösenord igen"
              onChange={event => this.handleInputChange(event)}
            />
          </div>
          <button className="btn btn-primary" type="submit">
            Lägg till
          </button>
        </form>
      </div>
    );
  }
}

export default AddUser;
